<template>
  <v-row justify="center">
  <image-template
    :onChangeDialog="handleChangeDialog"
    :image="image ? image : defaultImage"
  />
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card style="background-color: gray !important;">
        <header-select-image
          :onChangeDialog="handleChangeDialog"
          :onSave="handleSave"
          :locale="locale"
        />
        <capture-image
          :key="forceClear"
          :onChange="handleCaptureNewImage"
          :imageWidth="imageWidth"
          :imageHeight="imageHeight"
          :aspectRatio="aspectRatio"
          :folder="folder"
          :limit="limit"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import ImageTemplate from './ImageTemplate'
import HeaderSelectImage from './HeaderSelectImage'
import CaptureImage from './CaptureImage'
export default {
  name: 'SelectImage',
  components: {
    ImageTemplate,
    HeaderSelectImage,
    CaptureImage,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
    defaultImage: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      default: null,
    },
    locale: {
      type: String,
      defauld: null,
    },
    imageWidth: {
      type: Number,
      default: null,
    },
    imageHeight: {
      type: Number,
      default: null,
    },
    aspectRatio: {
      type: Number,
      default: null,
    },
    folder: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    newImage: null,
    forceClear: null,
  }),
  watch: {
    dialog (v) {
      if (v) this.forceClear = new Date().getTime()
    },
  },
  methods: {
    handleCaptureNewImage (v) {
      this.newImage = v
    },
    handleSave () {
      this.onChange(this.newImage)
      this.dialog = false
    },
    handleChangeDialog (v) {
      if (this.onChange) {
        this.dialog = v
      }
    },
  },
}
</script>

