<template>
  <v-layout wrap v-if="content">
    <v-flex xs12 style="padding: 10px">
      <!--select-image 
        :image="content.Image"
        :onChange="handleChangeImage"
        :locale="locale"
        :imageWidth="800"
        :imageHeight="600"
        :aspectRatio="16/9"
        :folder="workspaceID"
      /-->
      <select-image 
        :image="content.Image"
        :onChange="handleChangeImage"
        :locale="locale"
        :folder="workspaceID"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import SelectImage from '@/components/selectImage/Index'
export default {
  name: 'Form3Space',
  components: {
    SelectImage,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    content: null,
  }),
  computed: {        
    ...mapState('app', ['locale', ]),
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    prepareContent () {
      this.content = this.editedItem
    },
    handleChangeImage (v) {
      if (v) {
        this.content.Image = v.base64
        this.content.newImage = v
        this.handleChangeIsModified()
      }
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
  },
}
</script>

