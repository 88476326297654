<template>
  <v-toolbar
    dark
    color="primary"
  >
    <v-btn
      icon
      dark
      @click="onChangeDialog(false)"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-toolbar-title>{{ $t('common.selectImage', locale) }}</v-toolbar-title>
    <v-spacer />
    <v-toolbar-items>
      <v-btn
        dark
        text
        @click="onSave"
      >
        {{ $t('common.ok', locale) }}
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>
<script>
export default {
  name: 'headerSelectImage',
  props: {
    onChangeDialog: {
      type: Function,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
  },
}
</script>

