<template>
  <v-img
    v-if="image"
    class="grey lighten-2 image-template-image"
    :src="image"
    :lazy-src="image"
    aspect-ratio="1.7"
    @click="onChangeDialog(true)"
  />
  <div
    v-else
    class="no-image-template"
    @click="onChangeDialog(true)"
  >
    <div 
      class="main-icon" 
    >
      <center><v-icon class="icon">mdi-image-plus</v-icon></center>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImageTemplate',
  props: {
    onChangeDialog: {
      type: Function,
      required: true,
    },
    image: {
      type: String,
      default: null,
    },
  },
}
</script>
<style scoped>
.image-template-image {
  border-radius: 10px;border: 0.5px solid #56a09930 !important;
  height: 120px;
  width: 100%;
}
.no-image-template {
  background-color: #e0e0e0 !important;
  height: 120px;
  width: 100%;
  border-radius: 10px;
  border: 0.5px solid #56a09930 !important;
  cursor:pointer;
  background-color: red;
}
.no-image-template .main-icon {
  margin-top: 30px;
  width: 100%;
}
.no-image-template .icon {
  font-size: 60px;
  color: #FFFFFF80;
}
</style>

